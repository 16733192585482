import { createStore, applyMiddleware, combineReducers } from "redux";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { sessions } from "./session/reducers";
import { user } from "./user/reducers";
import { sunset } from "./sunset/reducers";

export const history = createBrowserHistory();

export default createStore(
    combineReducers({
        router: connectRouter(history),
        sessions: sessions,
        user: user,
        sunset: sunset
    }),
    {},
    composeWithDevTools(
        applyMiddleware(
            routerMiddleware(history)
        )
    )
);
