import * as React from "react";
import { connect } from 'react-redux';
import { isUndefined } from "util";
import { Button, Multiselect } from "../../lib/polaris";
import { setTags } from "../../stores/session/actions";

export class Filter extends React.Component<any> {

    state = {
    }

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
    }

    getSessionOptions(): any[] {

        const tags = this.props.sessions.tags.session.Tags;

        return tags.map((v: string, i: number) => ({
            "label": v,
            "id": `session_${i}`,
            "filteringTags": [v]
        }));

        return [
            {
                "label": "Option 4",
                "id": "4",
                "filteringTags": [
                    "filtering",
                    "tags",
                    "these are filtering tags"
                ]
            }
        ]
    }

    getTrackOptions(): any[] {
        const tags = this.props.sessions.tags.tracks.Tags;

        return tags.map((v: string, i: number) => ({
            "label": v,
            "id": `track_${i}`,
            "filteringTags": [v]
        }));
    }

    onSelect(items: any[]) {
        if (items.length == 0) {
            setTags([], this.props.dispatch);
        } else {
            setTags(items.map(f => f.label), this.props.dispatch);
        }
    }

    getSelectedOptions(): any[] {
        const tags = this.props.sessions.selectedTags as string[];
        const options = [...this.getTrackOptions(), ...this.getSessionOptions()];
        console.log(tags);

        return options.filter(f => tags.indexOf(f['label']) > -1)
    }

    getClearButton() {
        if(this.props.sessions.selectedTags.length == 0){
            return (
                <React.Fragment></React.Fragment>
            )
        }

        return (
            <Button variant="link" onClick={() => this.onSelect([])}
                disabled={this.props.sessions.fetching || false}
            >
                Clear
            </Button>
        )
    }

    render() {
        return (
            <React.Fragment>

                <div className="awsui-row">
                    <div className="col-xxs-11">
                        <Multiselect
                            disabled={this.props.sessions.fetching || false}
                            onChange={(e) => {
                                this.onSelect(e.detail.selectedOptions)
                            }}
                            enableFiltering={true}
                            options={[
                                {
                                    "label": "Sessions",
                                    "options": this.getSessionOptions(),
                                    "id": "session-group",
                                },
                                {
                                    "label": "Tracks",
                                    "options": this.getTrackOptions(),
                                    "id": "tag-group",
                                }
                            ]}
                            placeholder="Choose session and tracks"
                            selectedLabel="Selected"
                            selectedOptions={this.getSelectedOptions()}
                        ></Multiselect>
                    </div>
                    <div className="col-xxs-1">
                        {this.getClearButton()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: any) {
    return state;
}

function mapDispatchToProps(dispatch: any) {
    return {
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);