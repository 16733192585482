import { SelectedSession, Session } from "./interfaces";
import {getSessions} from "./service";

export const SESSIONS_FETCH = "SESSIONS_FETCH";
export const SESSIONS_SET = "SESSIONS_SET";
export const SESSION_SELECT = "SESSION_SELECT";
export const TERM_SET = "TERM_SET";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const TIMEZONE_SET = "TIMEZONE_SET";
export const TAGS_SET = "TAGS_SET";

export const fetchSessions = (dispatch : any, query: string, tags : string[]) => {
    dispatch({
        type: SESSIONS_FETCH
    });

    (async () => {
        const response = await getSessions(query, tags);
        dispatch({
            type: SESSIONS_SET,
            content: response
        });
    })();
};

export const setTerm = (term : string, dispatch : any) => {
    dispatch({
        type: TERM_SET,
        content: term
    });
}

export const setTags = (tags : string[], dispatch : any) => {
    dispatch({
        type: TAGS_SET,
        content: tags
    });
}

export const selectSession = (dispatch : any, session: SelectedSession) => {
    dispatch({
        type: SESSION_SELECT,
        content: session
    });
}


export const toggleModal = (dispatch : any) => {
    dispatch({
        type: TOGGLE_MODAL
    });
}

export const setTimezone = (timezone : string, dispatch : any) => {
    dispatch({
        type: TIMEZONE_SET,
        content: timezone
    });
}

