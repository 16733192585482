import * as React from "react";
import { useState } from "react";
import { connect } from 'react-redux';
import { FormField, Button, ColumnLayout, Input, Icon, Badge, Modal, Select } from "../../lib/polaris";
import { fetchSessions, selectSession, setTerm, setTimezone, toggleModal } from "../../stores/session/actions";
import * as moment from "moment";
import { Cart } from "./cart";
import { SelectedSession, Session } from "../../stores/session/interfaces";
import { Time } from "./time";
import { Filter } from "./filter";
import { SunsetModal } from "./sunset-modal";
const tz = require("spacetime-informal");

const zones = [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Algiers',
    'Africa/Bissau',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/El_Aaiun',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Khartoum',
    'Africa/Lagos',
    'Africa/Maputo',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Sao_Tome',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Asuncion',
    'America/Atikokan',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Cayenne',
    'America/Chicago',
    'America/Chihuahua',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Fort_Nelson',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Nuuk',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Sitka',
    'America/St_Johns',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Atyrau',
    'Asia/Baghdad',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Colombo',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kathmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qostanay',
    'Asia/Qyzylorda',
    'Asia/Riyadh',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ulaanbaatar',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yangon',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faroe',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/Stanley',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/Perth',
    'Australia/Sydney',
    'CET',
    'CST6CDT',
    'EET',
    'EST',
    'EST5EDT',
    'Etc/GMT',
    'Etc/GMT+1',
    'Etc/GMT+10',
    'Etc/GMT+11',
    'Etc/GMT+12',
    'Etc/GMT+2',
    'Etc/GMT+3',
    'Etc/GMT+4',
    'Etc/GMT+5',
    'Etc/GMT+6',
    'Etc/GMT+7',
    'Etc/GMT+8',
    'Etc/GMT+9',
    'Etc/GMT-1',
    'Etc/GMT-10',
    'Etc/GMT-11',
    'Etc/GMT-12',
    'Etc/GMT-13',
    'Etc/GMT-14',
    'Etc/GMT-2',
    'Etc/GMT-3',
    'Etc/GMT-4',
    'Etc/GMT-5',
    'Etc/GMT-6',
    'Etc/GMT-7',
    'Etc/GMT-8',
    'Etc/GMT-9',
    'Etc/UTC',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'HST',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Reunion',
    'MET',
    'MST',
    'MST7MDT',
    'PST8PDT',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Wake',
    'Pacific/Wallis',
    'WET',
]

export class Home extends React.Component<any> {
    state = {
        searchVal: '',
        loading: false,
    }

    tableElement: HTMLTableElement | null = null;

    constructor(props: any) {
        super(props);
        console.log(props);
    }

    componentDidMount() {
    }

    search() {
        
        fetchSessions(this.props.dispatch, this.state.searchVal, this.props.sessions.selectedTags);
        
    }

    isSelected(index: number, id: string) {
        const selected: SelectedSession[] = (this.props.sessions?.selected || []);
        if (selected.filter(f => f.id == id).length) {
            return (
                <span style={
                    {
                        padding: '0 0 0 .5rem'
                    }
                }>
                    <Icon variant={index == 0 ? "normal" : "success"} name="status-positive"></Icon>
                </span>
            )
        }
    }

    exportModalRow(f: SelectedSession) {

        const borderStyle =
        {
            borderBottom: "1px solid white"
        };

        return (
            <tr>
                <td style={{
                    ...borderStyle,
                    paddingLeft: "1rem"
                }} className="col-2">{Time.format(f.start.timestamp, f.end.timestamp, this.props)}</td>
                <td style={{
                    ...borderStyle,
                    paddingLeft: "1rem"
                }} className="col-6">{f.title}</td>
                <td style={{
                    ...borderStyle,
                    textAlign: "center"
                }} className="col-4">
                    <a target="_blank" href={`https://virtual.awsevents.com/kwebcast/events/addtocalendar/entryid/${f.id}`}>Outlook  <Icon name="external"></Icon></a>
                </td>
                <td style={{
                    ...borderStyle,
                    textAlign: "center"
                }} className="col-4">
                    <a target="_blank" href={`https://virtual.awsevents.com/kwebcast/events/add-to-google-calendar/entryid/${f.id}`}>Google Calendar  <Icon name="external"></Icon></a>
                </td>
            </tr>
        )
    }

    copyCodeToClipboard = () => {
        const el = this.tableElement;
        if (el) {
            const range = document.createRange();
            const sel = window.getSelection();
            if (sel) {
                sel.removeAllRanges();
                range.selectNodeContents(el);
                sel.addRange(range);
                document.execCommand("copy");
                sel.removeAllRanges();
            }
        }
    }

    exportModal() {

        const borderStyle =
        {
            borderBottom: "1px solid white"
        };


        return (
            <Modal
                header="Agenda"
                visible={this.props.sessions.modalVisible}
                onDismiss={() => toggleModal(this.props.dispatch)}
                expandToFit={true}
                footer={(
                    <Button icon="copy" label="Copy [content name]" variant="primary" onClick={() => {
                        this.copyCodeToClipboard();
                    }}>Copy</Button>
                )}
                size="max"
            >
                <table width="100%" ref={(table) => { this.tableElement = table }}>
                    <thead>
                        <tr>
                            <th style={borderStyle}>Date</th>
                            <th style={borderStyle}>Title</th>
                            <th style={borderStyle} colSpan={2} >Add To Calendar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.sessions.selected
                            .sort((a: any, b: any) => a.start.timestamp < b.start.timestamp ? -1 : 1)
                            .map((f: any) => this.exportModalRow(f))}
                    </tbody>
                </table>
            </Modal >
        )
    }

    sessionButtons(session: Session, slot: any, index: number) {
        return (
            <Button variant={index == 0 ? "primary" : "normal"}
                key={`${slot.id}`}
                onClick={
                    (e) => {
                        selectSession(this.props.dispatch, {
                            id: slot.id,
                            description: session.description,
                            title: session.title,
                            thumbnailUrl: session.thumbnailUrl,
                            start: slot.start,
                            end: slot.end
                        });

                        e.preventDefault();
                    }
                }
            >
                {Time.format(slot.start.timestamp, slot.end.timestamp, this.props)}
                {this.isSelected(index, slot.id)}
            </Button>
        )
    }

    getTimezone() {
        let clean = zones.map(f => tz.display(f));
        clean = clean.filter(f => f).filter(f => (f.daylight || f.standard) != null)
        clean = clean.map(f => f.daylight || f.standard);

        var seen: { [key: string]: any } = {};
        let final = clean.filter(function (item) {
            return seen.hasOwnProperty(item.abbrev) ? false : (seen[item.abbrev] = true);
        });

        final = final.sort(function (a, b) {
            var nameA = a.abbrev.toUpperCase(); // ignore upper and lowercase
            var nameB = b.abbrev.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1; //nameA comes first
            }
            if (nameA > nameB) {
                return 1; // nameB comes first
            }
            return 0;  // names must be equal
        }).map(f => ({
            "id": f.abbrev,
            "label": f.abbrev,
            "description": f.name,
        }));

        const t = tz.display(this.props.sessions.timeZone);
        const t2 = (t.daylight || t.standard).abbrev;

        const selectedOption = final.find(f => f.id == t2);

        return (
            <Select
                enableFiltering={true}
                selectedOption={selectedOption}
                options={final}
                onChange={(e) => {
                    const val = tz.find(e.detail.selectedId);
                    setTimezone(val, this.props.dispatch)
                }}
            />
        )
    }

    results() {
        const results = (this.props.sessions.sessions?.items || []).map((f: any, index: number) => (
            <div className="awsui-row">
                <div className="col-xxs-12" key={`${index}`}>
                    <div className="awsui-row">
                        <div className="awsui-util-container" style={{
                            width: "100%"
                        }}>
                            <div className="awsui-util-container-header">
                                <h2>{f.title}</h2>
                            </div>
                            <div className="awsui-row">
                                <div className="col-s-6 col-m-8">
                                    {f.description}

                                </div>
                                <div className="col-m-3" style={{ textAlign: "right" }}>
                                    <img src={f.thumbnailUrl} alt="" />
                                </div>
                            </div>
                            <div className="awsui-util-container-footer">
                                {
                                    f.sessions.sort((a: any, b: any) => a.start.timestamp < b.start.timestamp ? -1 : 1).map((slot: any, index: number) => this.sessionButtons(f, slot, index))
                                }
                                <ul style={{
                                    listStyle: "none",
                                    // width: "30%",
                                    display: "inline-block",
                                    textAlign: "right"
                                }}>
                                    {f.tags.map((t: string, subindex: number) => (<li
                                        key={`${index}_${subindex}`}
                                        style={{
                                            display: "inline-block",
                                            margin: "0 1rem"
                                        }}
                                    ><Badge>{t}</Badge></li>))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))


        if (results.length > 0) {
            const wrapper = (
                <div className="resultsWrapper"
                    style={{
                        maxHeight: "70vh",
                        minHeight: "70vh",
                        overflow: "auto",
                        overflowX: "hidden",
                        width: "100%"
                    }}>
                    {results}
                </div>
            )

            return [
                <div key="results" className="col-xxs-12">
                    <div className="awsui-row">
                        <div className="col-xxs-9">{this.props.sessions.sessions?.count} Results</div>
                        <div className="col-xxs-3">{this.getTimezone()}</div>
                    </div>
                </div>, wrapper];
        } else {
            return [];
        }
    }

    searchIcon() {
        if (!this.props.sessions.fetching) {
            return (<Icon name="search" ></Icon>)
        }
    }

    render() {
        return (
            <div className="awsui-grid awsui-util-p-s" style={
                {
                    padding: "2rem",
                }
            }>
                <div className="awsui-row">
                    <div className="col-xxs-9">
                        <h4>Internal Use Only</h4>
                    </div>
                </div>
                <div className="awsui-row">
                    <div className="col-xxs-9">
                        <div className="custom-home__header custom-awsui-util-pt-xxxl awsui-row"
                            style={
                                {
                                    borderBottom: "1px solid #ffffff47",
                                    paddingBottom: "5px",
                                    marginBottom: "5px",
                                }
                            }
                        >
                            <div className="col-xxs-12">
                                <div className="awsui-row">
                                    <div className="col-xxs-12">

                                        <div className="awsui-form-field-hint" style={{
                                            display: "inline-block",
                                            width: "100%",
                                            marginRight: "1rem"
                                        }}>
                                            <div className="filters awsui-row">

                                                <div className="filters col-xxs-5">
                                                    <Input placeholder="Lookup re:Invent sessions (required)" type="text" disabled={(this.props.sessions.fetching || false)}
                                                        onKeyup={(e) => { if (e.detail.key === "Enter") this.search() }}
                                                        onChange={(e) => {
                                                            this.state.searchVal = e.detail.value;
                                                            setTerm(this.state.searchVal, this.props.dispatch);
                                                        }}
                                                    ></Input>
                                                </div>
                                                <div className="filters col-xxs-5">
                                                    <Filter {...this.props}></Filter>
                                                </div>


                                                <div className="filters col-xxs-2"
                                                    style={
                                                        {
                                                            textAlign: "right"
                                                        }
                                                    }>
                                                    <Button loading={(this.props.sessions.fetching || false)} variant="primary"
                                                        onClick={() => this.search()} >
                                                        {this.searchIcon()}Search
                                </Button>
                                                </div>
                                            </div>

                                            <div className="awsui-form-field-hints">
                                                <div className="awsui-row">

                                                    <div className="col-xxs-6">
                                                        Lookup powered by
                                                        <a style={{ marginLeft: ".5rem" }} key="link" href="https://aws.amazon.com/kendra/" target="_blank" rel="noopener">
                                                            Amazon Kendra <Icon name="external"></Icon>
                                                        </a>
                                                    </div>
                                                    <div className="col-xxs-6" style={
                                                        {
                                                            textAlign: "right"
                                                        }
                                                    }>
                                                        Built by <a target="_blank" href="https://phonetool.amazon.com/users/jsstand">John Standish <Icon name="external"></Icon></a>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-xxs-3 col-m-1">
                            </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="custom-awsui-util-pt-xxxl awsui-row">
                            <div className="col-xxs-12">
                                <div className="awsui-row">
                                    {
                                        this.results()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxs-3" style={
                        {
                            position: "fixed",
                            height: "100%",
                            right: "0",
                            overflow: "auto",
                            top: "0",
                            minWidth: "25%"
                        }
                    }>
                        <Cart {...this.props}></Cart>
                    </div>
                </div>
                {this.exportModal()}
                <SunsetModal {...this.props}></SunsetModal>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return state;
}

function mapDispatchToProps(dispatch: any) {
    return {
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);