import {SUNSET_DISMISS} from "./actions";


const initialState = {
    dismiss: false
};



export function sunset(state = initialState, action: any) {
    switch (action.type) {
        case SUNSET_DISMISS: {
            return {
                dismiss: true
            };
        }
        default:
            return state;
    }
}
