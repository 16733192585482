/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "search",
            "endpoint": "https://o14ufosmkg.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:25f4c71d-07f1-4a89-967c-7929f62f2ca5",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_wVOdkKiQb",
    "aws_user_pools_web_client_id": "7htq6g9crsvd03b4qtjsa37n8m",
    "oauth": {
        "domain": "reinvent-poc.auth.us-west-2.amazoncognito.com",
        "scope": ["openid", "email"],
        "redirectSignIn": "https://jsstand-reinvent.sa.aws.dev/",
        "redirectSignOut": "https://jsstand-reinvent.sa.aws.dev/",
        "responseType": "code"
    },
    "aws_appsync_graphqlEndpoint": "https://iacu4vxsq5gwzomjvh3fv64rzi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "logs-table-mainline",
            "region": "us-west-2"
        }
    ],
    "aws_mobile_analytics_app_id": "f01e22927b2d453ba7cfc888b45d2d7a",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
