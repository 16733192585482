import * as React from "react";
import { connect } from 'react-redux';
import { Modal, Button } from "../../lib/polaris";
import { dismissModal } from "../../stores/sunset/actions";

export class SunsetModal extends React.Component<any> {

    state = {
    }

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
    }

    dismiss(){
        dismissModal(this.props.dispatch);
    }


    render() {
        return (
            <React.Fragment>
                <Modal
                    visible={!this.props.sunset.dismiss}
                    header="Thank you!"
                    footer={<span className="awsui-util-f-r">
                        <Button variant="primary" onClick={() => this.dismiss()}>
                            Dismiss
                        </Button>
                    </span>}
                >
                    <p>Thank you all for your input and kind words! <br/> </p>
                    <p>We will no longer be adding features to this tool as the official re:Invent 2020 site now has robust search functionallity. We're looking to sunset this tool on Dec 4, 2020. If you have any concerns with this, please reach out directly.</p>
                    <p>
                        <br/>
                    <b>Please visit the official search experience!</b>
                    </p>
                    <a target="_blank" href="https://virtual.awsevents.com/esearch/search">https://virtual.awsevents.com/esearch/search</a>
                    <p>
                        <br/>
                        <i>We will be posting the source internally for those builders that are interested.</i>
                    </p>
</Modal>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: any) {
    return state;
}

function mapDispatchToProps(dispatch: any) {
    return {
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SunsetModal);