import React from 'react';
import { useSelector } from "react-redux";
import { withOAuth } from "aws-amplify-react";
import {ConnectedRouter, push} from "connected-react-router";
import { Switch } from 'react-router';
import logo from './logo.svg';
import './App.css';
import {history} from './stores';
import { AppLayout, SideNavigation, TokenGroup } from "./lib/polaris";
import { ToRoutes } from './routes';
import { userStateSubscribe, userTryLogin } from './stores/user/actions';

function App(props: any) {
    const user = useSelector((state: any) => state.user);

    userStateSubscribe(props.store.dispatch);

    if (user.authState !== "signedIn") {
        userTryLogin(props.store.dispatch);
    }

    history.listen((change: any) => {
        console.log('location', change);
    });

    if (user.authState === "loading") {
        return (
            <div className="awsui">
                Loading...
            </div>
        )
    } else {
        return (
            <div className="awsui awsui-polaris-dark-mode" >
                < AppLayout
                    contentType={"no-paddings"}
                    toolsHide={true}
                    navigationHide={true}
                    content={
                        < ConnectedRouter history={history} >
                            <Switch>
                                {ToRoutes()}
                            </Switch>
                        </ConnectedRouter >
                    }
                />
            </div >
        );
    }
}

export default withOAuth(App);
