import * as React from "react";
import { useState } from "react";
import { connect } from 'react-redux';
import { FormField, Button, ColumnLayout, Input, Icon, Badge, ExpandableSection, TokenGroup } from "../../lib/polaris";
import { fetchSessions, selectSession, toggleModal } from "../../stores/session/actions";
import moment from "moment";
import { SelectedSession } from "../../stores/session/interfaces";
import { Time } from "./time";

export class Cart extends React.Component<any> {
    weeks: {
        [key: string]: {
            start: moment.Moment,
            end: moment.Moment
        }
    } = {
            '1': {
                start: moment('2020-11-30'),
                end: moment('2020-12-5').endOf("day"),
            },
            '2': {
                start: moment('2020-12-07'),
                end: moment('2020-12-12').endOf("day"),
            },
            '3': {
                start: moment('2020-12-14'),
                end: moment('2020-12-18').endOf("day")
            },
        }

    state = {
    }

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
    }

    getResultsByTimeframe(selected: SelectedSession[], start: moment.Moment, end: moment.Moment): SelectedSession[] {
        const found = selected.filter(f => moment.unix(f.start.timestamp).isBetween(start.utc(), end.utc()));
        return found;
    }

    getSection(weekKey: string, sessions: SelectedSession[]) {

        const w = this.weeks[weekKey];
        const start = moment(w.start);
        const end = moment(w.end);

        const results = this.getResultsByTimeframe(sessions || [], start, end).sort((a, b) => a.start.timestamp > b.start.timestamp ? -1 : 1);

        if (results.length == 0) {
            return (
                <React.Fragment></React.Fragment>
            )
        };

        const items = results.map(f => {
            return {
                "label": f.title,
                "description": Time.format(f.start.timestamp, f.end.timestamp, this.props),
            }
        });

        return (
            <ExpandableSection header={`Week ${weekKey} (${start.format("MMM D")} - ${end.format("MMM D")})`} expanded={true}>
                <TokenGroup alignment="vertical"
                    items={items}
                    onDismiss={
                        (e) => {
                            selectSession(this.props.dispatch, results[e.detail.itemIndex]);
                        }
                    }
                ></TokenGroup>
            </ExpandableSection>
        )
    }

    export(sessions: SelectedSession[]) {
        if (sessions?.length) {
            return (
                <Button variant="primary" onClick={() => toggleModal(this.props.dispatch)}>
                    Export
                </Button>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container"
                style={
                    {
                        maxHeight:"calc(100% - 60px)",
                        overflow: "auto"
                    }
                }>
                    {this.getSection('1', this.props.sessions?.selected)}
                    {this.getSection('2', this.props.sessions?.selected)}
                    {this.getSection('3', this.props.sessions?.selected)}
                </div>
                <div className="export-bar"
                style={
                    {
                        margin:"15px 0"
                    }
                }
                >
                    {this.export(this.props.sessions?.selected)}
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: any) {
    return state;
}

function mapDispatchToProps(dispatch: any) {
    return {
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);