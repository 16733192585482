import * as React from "react";
import { connect } from 'react-redux';
import moment from "moment";
import spacetime from 'spacetime';
const tz = require("spacetime-informal");

export class Time extends React.Component<{
    start: number,
    end: number,
    dispatch: any
}> {
    constructor(props: any) {
        super(props);
        console.log(props);
    }

    componentDidMount() {
    }

    static format(start : number, end: number, props: any) : string{

        const startSpace = spacetime(start * 1000).goto(props.sessions.timeZone as string);
        const endSpace = spacetime(end * 1000).goto(props.sessions.timeZone as string);

        const t = tz.display(props.sessions.timeZone);
        const timeZone = (t.daylight || t.standard).abbrev;

        return `${startSpace.unixFmt('eee, MM/d h:mma')} - ${endSpace.unixFmt('h:mma')} ${timeZone}`;
    }

    render() {
        return (
            <React.Fragment>
                {Time.format(this.props.start, this.props.end, this.props.dispatch)}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: any) {
    return state;
}

function mapDispatchToProps(dispatch: any) {
    return {
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Time);