import { Hub, Auth } from "aws-amplify";

export const USER_SIGNEDIN = "USER_SIGNEDIN";

export const userStateSubscribe = (dispatch: any) => {

    Hub.listen('auth', (data : any) => {
        switch (data.payload.event) {
            case 'signIn':
                dispatch({
                    type: USER_SIGNEDIN,
                    content: data.payload.data
                })
                break;
            case 'signIn_failure':
                dispatch({
                    type: USER_SIGNEDIN,
                    content: data.payload.data
                })
                break;
            default:
                break;
        }
    });
};

export const userTryLogin = (dispatch: any) => {

    const isLocal = window.location.hostname.indexOf("localhost") > -1;

    setTimeout(() => {
        if (isLocal) {
            dispatch({
                type: USER_SIGNEDIN,
                content: {}
            });
        } else {
            // todo: Uncomment when redirect url fixed and cognito configured
            Auth.currentAuthenticatedUser().then((user : any) => {
                console.log(user);
                dispatch({
                    type: USER_SIGNEDIN,
                    content: user
                })
            }).catch((e : any) => {
                console.log(e);
                // this.setState({ authState: 'signIn' });
                Auth.federatedSignIn();
            });
        }



    }, 2500);
};