import Content from "../../lib/polaris/internal/components/app-layout/src/layout/content";
import { SESSIONS_FETCH, SESSIONS_SET, SESSION_SELECT, TOGGLE_MODAL, TIMEZONE_SET, TAGS_SET, TERM_SET} from "./actions";
import { SelectedSession } from "./interfaces";
import {Tracks, Sessions} from './filters';
const initialState: {
    modalVisible: boolean,
    sessions: any[],
    selected: SelectedSession[],
    timeZone : string,
    selectedTags : string[],
    term: string,
    tags : {
        tracks: {
            AllValuesElement: string,
            Tags : string[]
        },
        session: {
            AllValuesElement: string,
            Tags : string[]
        }
    }
} = {
    modalVisible: false,
    sessions: [],
    selected: [],
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    selectedTags : [],
    term: '',
    tags : {
        tracks: Tracks,
        session: Sessions
    }
};

export function sessions(state = initialState, action: any) {
    switch (action.type) {
        case TERM_SET: {
            const { content } = action;
            return {
                ...state,
                tern: content
            };
        }
        case TAGS_SET: {
            const { content } = action;
            return {
                ...state,
                selectedTags: content
            };
        }
        case TOGGLE_MODAL: {
            return {
                ...state,
                modalVisible: !state.modalVisible
            };
        }
        case TIMEZONE_SET: {
            const { content } = action;
            return {
                ...state,
                timeZone: content
            };
        }
        case SESSIONS_SET: {
            const { content } = action;
            return {
                ...state,
                sessions: content,
                fetching: false
            };
        }
        case SESSIONS_FETCH: {
            // const {content} = action;
            return {
                ...state,
                fetching: true
            };
        }
        case SESSION_SELECT: {
            const exists = state.selected.filter(f => f.id == action.content.id).length !== 0;
            const selected = state.selected.filter(f => f.id != action.content.id);

            if (!exists) {
                selected.push(action.content);
            }

            return {
                ...state,
                selected: selected
            }

        }
        default:
            return state;
    }
}