import {Session} from "./interfaces";
import * as api from './api';
import { Auth } from "aws-amplify";


// @ts-ignore
const defaultApi = new api.DefaultApi();

export async function getSessions(term : string, tags: string[]) : Promise<{
    count: number,
    items : Session[]
}> {
    // var a = await Auth.currentSession();

    const result = await defaultApi.searchOptions(
        {
            "method" : "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            data: { 
                term: term,
                tags: tags
            }
        }
    );

    const res = (result.data.items as any[]);

    const response = res.map<any>( (p :any) : Session => {
        return {
            title :  p.title as string,
            description : p.description as string,
            sessions: p.items.map((f : any) => ({
                id: f.id,
                start: {
                    timestamp: f.schedulingData.start.timestamp,
                    offset: f.schedulingData.start.timeZoneOffset,
                },
                end: {
                    timestamp: f.schedulingData.end.timestamp,
                    offset: f.schedulingData.end.timeZoneOffset,
                },
            })),
            thumbnailUrl: p.items[0].thumbnailUrl,
            tags : (p.items[0].tags as string).split(','),
        };
    });


    // console.log(response);
    return {
        count : result.data.count,
        items: response
    };
}