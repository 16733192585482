import * as React from "react";
import {Route} from "react-router";
import Home from "./pages/home";


const routes: { path: string, component: React.FunctionComponent }[] = [
    {path: "/", component: Home},
];

console.log(routes);

export function ToRoutes() {
    return routes.map(v => <Route key={v.path} path={v.path} component={v.component}/>)
}