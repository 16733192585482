import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import Amplify from "aws-amplify";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./stores";
import awsconfig_export from "./aws-exports";
import awsconfig_export_dev from "./aws-exports.dev";

const isLocal = window.location.hostname.indexOf("localhost") > -1;
console.log("islocal", isLocal);

var awsconfig = {
    Auth: {

        identityPoolId: !isLocal ?  awsconfig_export.aws_cognito_identity_pool_id : awsconfig_export_dev.aws_cognito_identity_pool_id, //admin identity pool

        userPoolId: !isLocal ? awsconfig_export.aws_user_pools_id : awsconfig_export_dev.aws_user_pools_id, //admin user pool id

        userPoolWebClientId: !isLocal ?  awsconfig_export.aws_user_pools_web_client_id : awsconfig_export_dev.aws_user_pools_web_client_id, //admin app client id

        // REQUIRED - Amazon Cognito Region
        region: awsconfig_export.aws_cognito_region,

        oauth: !isLocal ? awsconfig_export.oauth : awsconfig_export_dev.oauth
    }
};

Amplify.configure(awsconfig);

ReactDOM.render(
    <Provider store={store}>
        <App store={store}/>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
