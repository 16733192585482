const Tracks = {
    AllValuesElement: "All Tracks",
    Tags: [
        "Advertising & Marketing",
        "Alexa",
        "Amazon.com",
        "Analytics",
        "Application Integration",
        "Architecture",
        "Artificial Intelligence & Machine Learning",
        "Automotive",
        "Blockchain",
        "Builder's Library",
        "Business Apps (including Connect)",
        "Business Intelligence",
        "Chinese",
        "Community",
        "Compute",
        "Consumer\/Packaged Goods",
        "Containers",
        "Databases",
        "DeepRacer",
        "DevOps",
        "End User Computing",
        "Energy",
        "Enterprise\/Migration",
        "Financial Services",
        "French",
        "Front-End Web & Mobile Development",
        "Games\/GameTech",
        "Global Partner Summit (GPS)",
        "Healthcare",
        "Innovation",
        "IoT",
        "Italian",
        "Japanese",
        "Korean",
        "Life Sciences",
        "Management Tools & Governance",
        "Manufacturing",
        "Marketplace",
        "Media & Entertainment",
        "Netflix",
        "Networking & Content Delivery",
        "Open Source",
        "Partner Solutions for Business",
        "Portuguese",
        "Power & Utilities",
        "Public Sector",
        "Quantum Computing",
        "Retail",
        "Robotics",
        "Security Compliance & Identity",
        "Serverless",
        "Spanish",
        "Startup",
        "Storage",
        "Telecommunications",
        "Travel & Hospitality",
        "We Power Tech",
        "Windows",
        ".NET"
    ]
};
const Sessions = {
    AllValuesElement: "All Sessions",
    Tags: [
        "Demo",
        "Dev Chat",
        "Executive Summit",
        "Hands-on Content",
        "Keynote",
        "Leadership Session",
        "Lightning Talk",
        "Play",
        "Session",
        "Training and Certification",
        "Video on Demand"
    ]
}


export { Sessions, Tracks };