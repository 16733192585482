import {USER_SIGNEDIN} from "./actions";


const initialState = {
    authState: 'loading', authData: null
};



export function user(state = initialState, action: any) {
    switch (action.type) {
        case USER_SIGNEDIN: {
            const { content } = action;
            return {
                ...state,
                authState: 'signedIn',
                authData: content
            };
        }
        default:
            return state;
    }
}
